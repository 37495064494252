<template>
  <div>
    <div class="d-flex justify-space-between align-end mb-8" ref="top_components_table">
      <div class="d-flex flex-column">
        <h4 class="mb-2">Search</h4>
        <v-text-field
          v-model="filter"
          solo
          style="max-width: 400px"
          hide-details
          placeholder="Type to search..."
          clearable
          @click:clear="filter = ''"
          :disabled="!this.ComponentList.length > 0"
        ></v-text-field>
      </div>

      <AddComponentForm
        color="success"
        v-if="this.checkPermission('can_access_full_management_menu') || true"
        @closeModal="CloseModal()"
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="filtered_component_list"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      calculate-widths
      hide-default-footer
      :loading="this.isLoadingComponents"
      loading-text="Loading... Please wait"
      class="elevation-1"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="EditItem(item)"> mdi-pen </v-icon>
        <v-icon
          small
          @click="
            deleteItem = item;
            deleteModal = true;
          "
          v-if="checkPermission('can_access_full_management_menu') || true"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
    </div>

    <EditComponentForm
      v-model="editFormVisible"
      :currentEditItem="CurrentSingleComponent"
      @closeModal="CloseModal()"
    />

    <ConfirmationDialog
      v-model="deleteModal"
      itemName="phrase"
      @cancelled="
        deleteModal = false;
        deleteItem = null;
      "
      @confirmed="DeleteItem()"
      :loader="isDeleting"
    />
  </div>
</template>

<script>
import AddComponentForm from './AddComponentForm';
import EditComponentForm from './EditComponentForm';
import ConfirmationDialog from '../../global/ConfirmationDialog.vue';

export default {
  name: 'RolesTable',
  components: {
    AddComponentForm,
    EditComponentForm,
    ConfirmationDialog,
  },
  data() {
    return {
      isLoadingComponents: false,
      page: 1,
      pageCount: 0,
      filter: '',
      editFormVisible: false,
      addFormVisible: false,
      deleteModal: false,
      deleteItem: null,
      isDeleting: false,
      itemsPerPage: 20,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
          width: 75,
        },
        { text: 'Part Code', value: 'part_code' },
        { text: 'Description', value: 'description', width: 250 },
        { text: 'Manufacturer', value: 'manufacturer' },
        { text: 'Package', value: 'package' },
        { text: 'Marking Code', value: 'marking_code' },
        { text: 'Location', value: 'location' },
        { text: 'Supplier', value: 'company.name' },
        { text: 'Order Code', value: 'order_code' },
        { text: 'Unit Price', value: 'unit_price' },
        { text: 'Qty In Stock', value: 'qty_in_stock' },

        { text: 'Actions', value: 'actions', sortable: false, width: 100 },
      ],
    };
  },
  methods: {
    CloseModal() {
      this.editFormVisible = false;
      this.addFormVisible = false;
      this.$store.commit('SetCurrentSingleComponent', null);
    },
    EditItem(component) {
      console.log(component);
      this.$store.commit('SetCurrentSingleComponent', component);
      this.editFormVisible = true;
      this.addFormVisible = false;
    },

    DeleteItem() {
      let component = this.deleteItem;
      if (!component || !component.id) return;
      console.log(component);
      this.isDeleting = true;
      this.$store
        .dispatch('DeleteSingleComponent', component.id)
        .then(() => {
          this.deleteItem = null;
          this.deleteModal = false;
          this.$store.commit('SetSnackbarData', {
            message: 'Component deleted successfully.',
          });
        })
        .finally(() => {
          this.isDeleting = false;
        });
    },
  },
  mounted() {
    this.isLoadingComponents = true;
    this.$store.dispatch('getComponents').finally(() => {
      this.isLoadingComponents = false;
    });
  },
  computed: {
    CurrentSingleComponent() {
      return this.$store.getters.GetCurrentSingleComponent;
    },
    ComponentList() {
      return this.$store.getters.GetComponentList;
    },
    filtered_component_list() {
      var filter = '';
      if (this.filter) filter = this.filter.trim();

      if (!filter.length > 0) return this.ComponentList;
      return this.ComponentList.filter((component) => {
        return (
          component['manufacturer']
            .toString()
            .toLowerCase()
            .includes(filter) ||
          (component['location'] &&
            component['location']
              .toString()
              .toLowerCase()
              .includes(filter)) ||
          (component['package'] &&
            component['package']
              .toString()
              .toLowerCase()
              .includes(filter)) ||
          (component['marking_code'] &&
            component['marking_code']
              .toString()
              .toLowerCase()
              .includes(filter)) ||
          (component['order_code'] &&
            component['order_code']
              .toString()
              .toLowerCase()
              .includes(filter)) ||
          component['part_code']
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          (component['description'] &&
            component['description']
              .toString()
              .toLowerCase()
              .includes(filter.toLowerCase())) ||
          (component['company'] &&
            component['company']['name']
              .toString()
              .toLowerCase()
              .includes(filter.toLowerCase()))
        );
        // str.includes("world");
      });
    },
  },
};
</script>

<style scoped>
.header-component-chip {
  background: #1976d2;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.9s ease;
}
.slide-fade-leave-active {
  transition: all 0.9s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(30px);
  opacity: 0;
}
</style>
