<template>
  <v-col class="px-5" v-if="this.job" md="3" sm="6" cols="12">
    <div class="table-cont" v-if="quotation_options && quotation_options.length > 0">
      <div class="table-header pa-3 d-flex">
        <div class="table-property-label">Applied Quotation Options</div>
      </div>
      <div class="table-row" v-for="option in quotation_options" :key="option.id">
        <div class="table-property-label">{{ option.name }}</div>
        <div class="table-property-value">{{ option.percentage }}%</div>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'SalQuotationOptions',

  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },

    quotation_options() {
      return this.job.quotation_options;
    },
  },
};
</script>

<style></style>
