<template>
  <v-form ref="AdvanceToStage3" v-model="valid" @submit.prevent="Submit">
    <v-row class="px-6 pt-6 ma-0">
      <div class="grid--3">
        <div>
          <h4 class="mb-2">Engineers Hours x 1</h4>

          <v-text-field
            solo
            v-model.number="formFields.engTime"
            name="eng_rate"
            class="hide-arrows"
            suffix="hours"
            type="number"
            @wheel="$event.target.blur()"
          ></v-text-field>
        </div>

        <div>
          <h4 class="mb-2">Engineers Hours x 1.5</h4>

          <v-text-field
            solo
            v-model.number="formFields.engTime_1_5"
            name="eng_rate"
            class="hide-arrows"
            suffix="hours"
            type="number"
            @wheel="$event.target.blur()"
          ></v-text-field>
        </div>

        <div>
          <h4 class="mb-2">Engineers Hours x 2</h4>

          <v-text-field
            solo
            v-model.number="formFields.engTime_2"
            name="eng_rate"
            class="hide-arrows"
            suffix="hours"
            type="number"
            @wheel="$event.target.blur()"
          ></v-text-field>
        </div>
      </div>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="3" class="mb-4 pa-6 pt-0">
        <h4 class="mb-2">Engineers Rate</h4>

        <v-text-field
          solo
          v-model.number="formFields.engTimerate"
          name="eng_rate"
          class="hide-arrows"
          prefix="£"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Engineers Time Quote</h4>

        <v-text-field
          solo
          v-model.number="formFields.engTimeQuote"
          name="eng_rate"
          class="hide-arrows"
          readonly
          disabled
          prefix="£"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Parts Cost</h4>

        <v-text-field
          solo
          :value="this.job.engParts"
          name="engParts"
          class="hide-arrows"
          disabled
          readonly
          prefix="£"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Parts Markup</h4>

        <v-text-field
          solo
          v-model.number="formFields.engPartsmarkup"
          name="engPartsmarkup"
          class="hide-arrows"
          suffix="%"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Parts Total Cost</h4>

        <v-text-field
          readonly
          disabled
          solo
          v-model.number="formFields.engPartsQuote"
          name="engPartsQuote"
          class="hide-arrows"
          prefix="£"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Components Cost</h4>

        <v-text-field
          solo
          :value="formFields.componentsCost"
          name="componentsCost"
          class="hide-arrows"
          disabled
          readonly
          prefix="£"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Components Markup</h4>

        <v-text-field
          solo
          v-model.number="formFields.componentsMarkup"
          name="componentsMarkup"
          class="hide-arrows"
          suffix="%"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Components Total Cost</h4>

        <v-text-field
          readonly
          disabled
          solo
          v-model.number="formFields.componentsTotalQuote"
          name="componentsTotalQuote"
          class="hide-arrows"
          prefix="£"
          type="number"
          @wheel="$event.target.blur()"
        ></v-text-field>

        <h4 class="mb-2">Engineers Notes</h4>

        <v-textarea solo v-model="formFields.engNotes" name="engNotes"></v-textarea>

        <h4 class="mb-2">Bin</h4>

        <v-text-field solo v-model="formFields.bin" name="bin"></v-text-field>

        <h4 class="mb-2">Engineering Quote Date</h4>

        <v-text-field
          solo
          name="engDate"
          @click="show_datepicker()"
          readonly
          :value="formFields.engDate | YMD_to_DMY"
        ></v-text-field>

        <v-dialog
          ref="datepicker_dialog"
          v-model="datepicker_modal"
          persistent
          :return-value.sync="temp_date"
          width="290px"
        >
          <v-date-picker v-model="temp_date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="hide_datepicker()">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="save_datepicker()"> OK </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-btn
          :disabled="!valid || isSubmitting"
          class="success mr-0 mt-3 ml-auto d-flex"
          type="submit"
          :loading="valid && isSubmitting"
          >Advance Job</v-btn
        >
      </v-col>

      <v-col cols="12" sm="9" class="mb-4 pa-6 pt-0">
        <div class="job-parts-wrapper">
          <v-btn class="d-flex ml-auto mb-3" @click="show_addPartModal = true">Add Part</v-btn>
          <v-simple-table v-if="job.parts && job.parts.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Supplier Name</th>
                  <th class="text-left">Part Code</th>
                  <th class="text-left">Description</th>
                  <th class="text-left">Qty</th>
                  <th class="text-left">Packs Ordered</th>
                  <th class="text-left">Total Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="part in job.parts" :key="part.id">
                  <td>{{ part.company ? part.company.name : '' }}</td>
                  <td>{{ part.part_code }}</td>
                  <td>{{ part.part_description }}</td>
                  <td>{{ part.pack_qty }}</td>
                  <td>{{ part.packs_ordered }}</td>
                  <td>{{ part.packs_cost | toCurrency }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="no-parts d-flex justify-center" v-else>
            No Parts were found for this job
          </div>
        </div>
        <div class="component-search-sep"></div>

        <div class="job-components-wrapper">
          <v-btn
            class="d-flex ml-auto mb-3"
            @click="show_addComponentModal = true"
            :disabled="isLoadingComponents"
            >Add Component to Job</v-btn
          >

          <CurrentJobComponents
            v-if="this.job.jobcomponents && this.job.jobcomponents.length > 0"
          />
          <span v-else class="no-parts d-flex justify-center"
            >No Components were found for this job</span
          >
        </div>
      </v-col>
    </v-row>
    <AddPartModal v-model="show_addPartModal" />
    <AddComponentModal v-model="show_addComponentModal" />
  </v-form>
</template>

<script>
import AddPartModal from '../tab2/AddPartModal.vue';
import AddComponentModal from './AddComponentModal.vue';
import CurrentJobComponents from '../job_components/CurrentJobComponents.vue';
export default {
  name: 'toStage3',
  components: {
    AddPartModal,
    AddComponentModal,
    CurrentJobComponents,
  },
  data() {
    return {
      isUpdating: false,
      show_addPartModal: false,
      show_addComponentModal: false,
      isSubmitting: false,
      isLoadingContacts: false,
      isLoadingComponents: false,
      datepicker_modal: false,
      temp_date: '',

      valid: false,

      formFields: {
        engTime: null,
        engTime_1_5: null,
        engTime_2: null,
        engTimerate: null,
        engTimeQuote: 0,
        engNotes: '',
        engParts: null,
        engPartsmarkup: 33,
        engPartsQuote: 0,
        componentsCost: null,
        componentsMarkup: 33,
        componentsTotalQuote: 0,
        bin: null,
        engDate: new Date().toISOString().substr(0, 10),
      },

      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        isNaN: (v) => this.isNumeric(v) == true || 'Wrong Value',
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },

  methods: {
    show_datepicker() {
      this.datepicker_modal = true;
      this.temp_date = '';
    },
    hide_datepicker() {
      this.datepicker_modal = false;
      this.temp_date = '';
    },
    save_datepicker() {
      if (this.temp_date) {
        this.formFields.engDate = this.temp_date;
      }
      this.hide_datepicker();
    },
    Submit() {
      console.log('Advancing to stage 3');
      console.log(this.formFields);
      console.log(this.validate());
      this.isSubmitting = true;
      this.formFields.engPartsQuote = this.jobPartsTotal;

      const payload = {
        job_id: this.job_id,
        data: this.formFields,
      };
      this.$store.dispatch('AdvanceToCustomerQuotation', payload).finally(() => {
        this.snackbar = true;
        this.isSubmitting = false;
        this.$router.push('/job/' + this.job_id);
      });
    },
    reset_form() {},
    validate() {
      return this.$refs.AdvanceToStage3.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },

    calculateEngTimeQuote() {
      const result =
        (this.formFields.engTime * this.formFields.engTimerate || 0) +
        (this.formFields.engTime_1_5 * this.formFields.engTimerate * 1.5 || 0) +
        (this.formFields.engTime_2 * this.formFields.engTimerate * 2 || 0);

      // Cap result to max 2 decimals
      this.formFields.engTimeQuote = parseFloat(result.toFixed(2));
    },
  },

  mounted() {
    this.isLoadingComponents = true;

    Promise.all([
      this.$store.dispatch('fetchSingleLabourRate', this.job.tag_id),

      this.$store.dispatch('fetchSuppliersList'),
      this.$store.dispatch('getComponents'),
    ])

      .then(([labourRate]) => {
        if (labourRate && labourRate.rate) this.formFields.engTimerate = labourRate.rate;
        // else {
        //   //fetch default value
        //   this.$store
        //     .dispatch("fetchSingleLabourRate", "")
        //     .then((defaultLabourRate) => {
        //       if (defaultLabourRate && defaultLabourRate.rate)
        //         this.formFields.engTimerate = defaultLabourRate.rate;
        //     });
        // }
      })
      .finally(() => {
        const keys = Object.keys(this.formFields);
        for (var i = 0; i < keys.length; i++) {
          var key = keys[i];
          console.log(key);
          if (this.job[key] && this.job[key] != '') this.formFields[key] = this.job[key];
        }

        this.formFields.engPartsQuote = this.jobPartsTotal;
        this.formFields.componentsCost = this.jobComponentsCost;
        this.formFields.componentsTotalQuote = this.jobComponentsTotal;
        this.isLoadingComponents = false;
      });
  },

  computed: {
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    job_id() {
      return this.$route.params.job_id;
    },
    jobPartsCount() {
      if (this.job) return this.$store.getters.getCurrentSingleJob.parts.length;
      else return [];
    },

    jobPartsTotal() {
      return this.job.engParts * (1 + this.formFields.engPartsmarkup / 100) || 0;
    },
    jobComponentsCost() {
      if (!this.job.jobcomponents || !this.job.jobcomponents.length > 0) return 0;
      let cost = 0;
      for (let i = 0; i < this.job.jobcomponents.length; i++) {
        console.log(this.job.jobcomponents[i]);
        cost +=
          parseInt(this.job.jobcomponents[i].quantity) *
          parseFloat(this.job.jobcomponents[i].component.unit_price);
      }
      return Math.round((cost + Number.EPSILON) * 100) / 100;
    },
    jobComponentsTotal() {
      return (
        Math.round(
          (this.jobComponentsCost * (1 + this.formFields.componentsMarkup / 100) + Number.EPSILON) *
            100
        ) / 100 || 0
      );
    },
  },
  watch: {
    'formFields.engTimerate': 'calculateEngTimeQuote',
    'formFields.engTime': 'calculateEngTimeQuote',
    'formFields.engTime_1_5': 'calculateEngTimeQuote',
    'formFields.engTime_2': 'calculateEngTimeQuote',

    jobPartsCount: function() {
      this.formFields.engPartsQuote = this.jobPartsTotal;
      // this.job.engParts * (1 + this.formFields.engPartsmarkup / 100) || 0;
    },
    jobComponentsCost: function() {
      this.formFields.componentsCost = this.jobComponentsCost;
      this.formFields.componentsTotalQuote = this.jobComponentsTotal;
    },
    'formFields.engPartsmarkup': function() {
      this.formFields.engPartsQuote = this.jobPartsTotal;
      // this.job.engParts * (1 + this.formFields.engPartsmarkup / 100) || 0;
    },
    'formFields.componentsMarkup': function() {
      this.formFields.componentsMarkup = this.jobComponentsTotal;
      // this.job.engParts * (1 + this.formFields.engPartsmarkup / 100) || 0;
    },
  },
};
</script>

<style scoped>
.component-search-sep {
  height: 6px;
  background: #eaeaea;
  margin: 60px 0px;
  width: 100%;
}

.grid--3 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 24px;
  width: 100%;
  max-width: 900px;
}

@media only screen and (max-width: 600px) {
  .grid--3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 0px;
  }
}
</style>
