<template>
  <v-form ref="AdvanceToStage2" v-model="valid" @submit.prevent="Submit">
    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="4" class="mb-4 pa-6">
        <h4 class="mb-2">Received Date</h4>

        <v-text-field
          solo
          name="rec_date"
          @click="show_datepicker()"
          readonly
          :value="formFields.recDate | YMD_to_DMY"
        ></v-text-field>

        <h4 class="mb-2">Order Number</h4>

        <v-text-field solo v-model="formFields.order_number" name="order_number"></v-text-field>

        <v-dialog
          ref="datepicker_dialog"
          v-model="datepicker_modal"
          persistent
          :return-value.sync="temp_date"
          width="290px"
        >
          <v-date-picker v-model="temp_date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="hide_datepicker()">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="save_datepicker()"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col cols="12" sm="4" class="mb-4 pa-6">
        <h4 class="mb-2">Description</h4>

        <v-text-field
          solo
          v-model="formFields.description"
          name="description"
          :rules="[rules.required]"
        ></v-text-field>

        <h4 class="mb-2">Manufacturer</h4>

        <!-- <v-text-field
          solo
          v-model="formFields.manufacturer"
          name="manufacturer"
          :rules="[rules.required]"
        ></v-text-field> -->

        <v-combobox
          solo
          clearable
          small-chips
          id="manufacturer_combobox"
          ref="manufacturer_combobox"
          v-model="formFields.manufacturer"
          name="manufacturer"
          :filter="filterManufacturers"
          :search-input.sync="search_key"
          :rules="[rules.required]"
          :items="ManufacturerList"
        ></v-combobox>
        <h4 class="mb-2">Model</h4>

        <v-text-field
          solo
          v-model="formFields.machine_model"
          name="machine_model"
          :rules="[rules.required]"
        ></v-text-field>
        <h4 class="mb-2">Serial</h4>

        <v-text-field
          solo
          v-model="formFields.serial"
          :rules="[rules.required]"
          name="serial"
        ></v-text-field>

        <h4 class="mb-2">Department</h4>

        <v-select
          :items="Departments"
          item-text="name"
          item-value="id"
          label="Department"
          solo
          name="tag_id"
          :rules="[rules.required]"
          v-model="formFields.tag_id"
        ></v-select>

        <!-- <h4 class="mb-2">Under Warranty?</h4>
        <v-select
          v-model="formFields.is_warranty"
          :items="warranty"
          label="Under Warranty?"
          item-value="value"
          item-text="name"
          solo
        ></v-select> -->
      </v-col>

      <v-col cols="12" sm="4" class="mb-4 pa-6">
        <h4 class="mb-2">Customer Reference</h4>

        <v-text-field
          solo
          v-model="formFields.customer_reference"
          name="customer_reference"
        ></v-text-field>

        <h4 class="mb-2">Priority</h4>

        <v-select
          :items="priorities"
          item-text="name"
          item-value="id"
          label="Priority"
          solo
          :loading="!formFields.priority_id"
          name="selected_priority"
          v-model="formFields.priority_id"
        ></v-select>

        <h4 class="mb-2">Subcontractor Search</h4>

        <v-text-field
          solo
          v-model="formFields.customer_reference"
          name="customer_reference"
        ></v-text-field>

        <v-textarea solo v-model="formFields.fault" name="fault" label="Faults"></v-textarea>

        <v-textarea
          solo
          v-model="formFields.internalnote"
          name="internal_note"
          label="Internal Note"
        ></v-textarea>

        <v-btn
          :disabled="!valid || isSubmitting"
          class="success mr-0 mt-3 ml-auto d-flex"
          type="submit"
          :loading="valid && isSubmitting"
          >Advance Job</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'toStage2',
  props: ['priority_id'],
  data() {
    return {
      isUpdating: false,
      isSubmitting: false,
      isLoadingContacts: false,
      datepicker_modal: false,
      temp_date: '',
      priorities: [
        { name: 'Normal', id: 2 },
        { name: 'High', id: 3 },
        { name: 'Emergency', id: 4 },
      ],
      warranty: [
        { name: 'No', value: false },
        { name: 'Yes', value: true },
      ],
      valid: false,
      search_key: '',
      formFields: {
        customer_reference: '',
        order_number: '',
        description: '',
        manufacturer: '',
        machine_model: '',
        serial: '',
        tag_id: null,
        // is_warranty: false,
        priority_id: '',
        fault: '',
        internalnote: '',
        recDate: new Date().toISOString().substr(0, 10),
      },

      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        isNaN: (v) => this.isNumeric(v) == true || 'Wrong Value',
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },

  methods: {
    show_datepicker() {
      this.datepicker_modal = true;
      this.temp_date = '';
    },
    hide_datepicker() {
      this.datepicker_modal = false;
      this.temp_date = '';
    },
    save_datepicker() {
      if (this.temp_date) {
        this.formFields.recDate = this.temp_date;
      }
      this.hide_datepicker();
    },
    Submit() {
      console.log('Advancing to stage 2');
      console.log(this.formFields);
      console.log(this.validate());
      this.isSubmitting = true;
      const payload = {
        job_id: this.job_id,
        data: this.formFields,
      };
      this.$store.dispatch('AdvanceToAssessment', payload).finally(() => {
        this.snackbar = true;
        this.isSubmitting = false;
        this.$router.push('/job/' + this.job_id);
      });
    },
    reset_form() {},
    validate() {
      return this.$refs.AdvanceToStage2.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },

  mounted() {
    this.$store.dispatch('fetchCompanyList');
    this.$store.dispatch('fetchManufacturersList');
    this.$store.dispatch('fetchDepartments');
    this.formFields.priority_id = this.job_priority;
    const keys = Object.keys(this.formFields);
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      console.log(key);
      if (this.job[key] && this.job[key] != '') this.formFields[key] = this.job[key];
    }
    this.formFields.description = '';
  },

  computed: {
    companyList() {
      return this.$store.getters.getCompanyList;
    },

    job() {
      return this.$store.getters.getCurrentSingleJob;
    },

    job_id() {
      return this.$route.params.job_id;
    },
    job_priority() {
      return this.$store.getters.getCurrentSingleJob.priority_id;
    },
    Departments() {
      return this.$store.getters.getDepartmentList;
    },
    ManufacturerList() {
      let list = this.$store.getters.getManufacturersList;
      if (list.length == 0) return [];
      if (!this.search_key) return list['initial'];
      else
        return this.$store.getters.getManufacturersList[
          this.search_key
            .charAt(0)
            .toString()
            .toUpperCase()
        ];
    },
  },

  watch: {
    job_priority: function() {
      this.formFields.priority_id = this.job_priority;
    },
    //   "formFields.selected_company": function (new_selection, old_selection) {
    //     if (new_selection == null) {
    //       this.isLoadingContacts = true;
    //       this.$store.commit("setCompanyContactList", []).finally(() => {
    //         this.isLoadingContacts = false;
    //       });
    //     } else if (new_selection != old_selection) {
    //       this.isLoadingContacts = true;
    //       this.$store
    //         .dispatch("fetchCompanyContactList", new_selection)
    //         .finally(() => {
    //           this.isLoadingContacts = false;
    //         });
    //     }
    //   },
  },
};
</script>

<style></style>
