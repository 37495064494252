import Axios from 'axios';

const state = {
  quotation_options_list: [],
};
const mutations = {
  SetQuotationOptionsList(state, payload) {
    state.quotation_options_list = payload;
  },
};
const actions = {
  FetchQuotationOptionsList(context) {
    var token = context.getters.getCurrentToken;
    console.log('token: ' + token);

    if (token != '')
      return Axios.get(
        process.env.VUE_APP_API_PROTOCOL +
          '://' +
          process.env.VUE_APP_API_HOST +
          ':' +
          process.env.VUE_APP_API_PORT +
          '/api/quotation_options'
      )
        .then(function(response) {
          console.log(response.data);
          context.commit('SetQuotationOptionsList', response.data);
          return response.data;
        })
        .catch(function(err) {
          console.log(err);

          context.commit('SetSnackbarData', {
            message: 'Error fetching quotation options list',
            timeout: 300000,
            type: 'error',
          });
          return Promise.reject(err);
        });
  },
};
const getters = {
  getQuotationOptionsList(state) {
    return state.quotation_options_list;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
