<template>
  <v-container fill-height fluid style="align-items: flex-start" class="pa-0">
    <v-form
      ref="EditJobForm"
      v-model="valid"
      @submit.prevent="Submit"
      style="width: 100%"
      :disabled="isFetchingJobData"
    >
      <v-tabs
        v-model="active_tab"
        background-color="deep-purple accent-4"
        center-active
        dark
        icons-and-text
      >
        <!-- <v-tabs-slider></v-tabs-slider> -->

        <v-tab href="#tab-1"> Edit </v-tab>

        <v-tab href="#tab-2"> Engineering Quote </v-tab>

        <v-tab href="#tab-3"> Sales Quote </v-tab>

        <v-tab href="#tab-4"> Job Report </v-tab>
      </v-tabs>

      <v-tabs-items v-model="active_tab">
        <v-tab-item :value="'tab-1'">
          <v-row class="pa-4 ma-0">
            <v-col cols="12" sm="3" class="mb-4 pa-6">
              <h4 class="mb-2">Priority</h4>

              <v-select
                :items="priorities"
                item-text="name"
                item-value="id"
                label="Priority"
                solo
                name="selected_priority"
                v-model="formFields.priority_id"
              ></v-select>

              <h4 class="mb-2">Order Number</h4>

              <v-text-field
                solo
                v-model="formFields.order_number"
                name="order_number"
              ></v-text-field>

              <h4 class="mb-2">Customer Reference</h4>

              <v-text-field
                solo
                v-model="formFields.customer_reference"
                name="customer_reference"
              ></v-text-field>

              <h4 class="mb-2">Department</h4>
              <v-select
                :items="Departments"
                item-text="name"
                item-value="id"
                label="Department"
                solo
                name="tag_id"
                v-model.number="formFields.tag_id"
              ></v-select>
              <div class="d-flex flex-column">
                <v-btn
                  @click="show_datepicker(date_item.property_name)"
                  :color="formFields[date_item.property_name] ? 'primary' : ''"
                  outlined
                  class="mb-3"
                  v-for="date_item in dateClusterLoop"
                  :key="date_item.property_name"
                >
                  {{ date_item.label }}:
                  {{ formFields[date_item.property_name] | YMD_to_DMY }}
                </v-btn>
              </div>
              <!-- <p @click="show_datepicker('engDate')">
                Eng Quote Date: {{ formFields.engDate | YMD_to_DMY }}
              </p>
              <p @click="show_datepicker('salDate')">
                Sales Quote Date: {{ formFields.salDate | YMD_to_DMY }}
              </p>
              <p @click="show_datepicker('appDate')">
                Approved: {{ formFields.appDate | YMD_to_DMY }}
              </p>
              <p @click="show_datepicker('staDate')">
                Started: {{ formFields.staDate | YMD_to_DMY }}
              </p>
              <p @click="show_datepicker('comDate')">
                Completed: {{ formFields.comDate | YMD_to_DMY }}
              </p>
              <p @click="show_datepicker('delDate')">
                Delivered: {{ formFields.delDate | YMD_to_DMY }}
              </p> -->
              <v-dialog
                ref="datepicker_dialog"
                v-model="datepicker_modal"
                persistent
                :return-value.sync="temp_date"
                width="290px"
              >
                <v-date-picker v-model="temp_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="hide_datepicker()">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="save_datepicker()">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="3" class="mb-4 pa-6">
              <h4 class="mb-2">Company</h4>
              <v-autocomplete
                v-model="formFields.company_id"
                :disabled="isUpdating"
                :items="companyList"
                solo
                color="blue-grey lighten-2"
                label="Select"
                item-text="name"
                item-value="id"
                hide-no-data
                :rules="[rules.required, rules.isNaN]"
                hide-selected
              >
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <h4 class="mb-2">Contact</h4>

              <v-select
                v-model="formFields.contact_id"
                label="Contact"
                item-value="id"
                :rules="[rules.required, rules.isNaN]"
                item-text="name"
                solo
                :items="CompanyContactList"
                :disabled="!CompanyContactList.length > 0"
                :loading="isLoadingContacts"
                loader-height="2"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="3" class="mb-4 pa-6">
              <h4 class="mb-2">Manufacturer</h4>

              <!-- <v-text-field
                solo
                v-model="formFields.manufacturer"
                name="manufacturer"
              ></v-text-field> -->

              <v-combobox
                solo
                clearable
                small-chips
                id="manufacturer_combobox"
                ref="manufacturer_combobox"
                v-model="formFields.manufacturer"
                name="manufacturer"
                :filter="filterManufacturers"
                :search-input.sync="search_key"
                :rules="[rules.required]"
                :items="ManufacturerList"
              ></v-combobox>

              <h4 class="mb-2">Model</h4>

              <v-text-field
                solo
                v-model="formFields.machine_model"
                name="machine_model"
              ></v-text-field>
              <h4 class="mb-2">Serial</h4>

              <v-text-field solo v-model="formFields.serial" name="serial"></v-text-field>

              <h4 class="mb-2">Description</h4>

              <v-text-field solo v-model="formFields.description" name="description"></v-text-field>

              <v-textarea
                solo
                v-model="formFields.internalnote"
                name="internal_note"
                label="Internal Note"
              ></v-textarea>
            </v-col>

            <v-col cols="12" sm="3" class="mb-4 pa-6">
              <h4 class="mb-2">Job Status</h4>
              <v-select
                v-model="formFields.status_id"
                :items="this.get_all_status"
                item-text="label"
                item-value="id"
                label="Job Status"
                solo
              ></v-select>

              <h4 class="mb-2">Assigned Engineer</h4>
              <v-select
                clearable
                v-model="formFields.user_id"
                :items="this.engineersList"
                item-text="name"
                item-value="id"
                label="Assigned Engineer"
                solo
              ></v-select>

              <h4 class="mb-2">RNR</h4>
              <v-select
                v-model="formFields.bRnr"
                :items="warranty"
                label="Is RNR?"
                item-value="value"
                item-text="name"
                solo
              ></v-select>

              <h4 class="mb-2">BER</h4>
              <v-select
                v-model="formFields.bBer"
                :items="warranty"
                label="Is RNR?"
                item-value="value"
                item-text="name"
                solo
              ></v-select>

              <h4 class="mb-2">Under Warranty?</h4>
              <v-select
                v-model="formFields.is_warranty"
                :items="warranty"
                label="Under Warranty?"
                item-value="value"
                item-text="name"
                solo
              ></v-select>

              <h4 class="mb-2">Subcontractor Search</h4>

              <v-autocomplete
                v-model="formFields.subcontractor_id"
                :disabled="isUpdating"
                :items="companyList"
                solo
                color="blue-grey lighten-2"
                label="Select"
                item-text="name"
                item-value="id"
                hide-no-data
                hide-selected
              >
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item :value="'tab-2'">
          <v-row class="pa-4 ma-0">
            <v-col cols="12" sm="3" class="mb-4 pa-6">
              <EngJobBasicInfo />
            </v-col>

            <v-col cols="12" sm="3" class="mb-4 pa-6">
              <h4 class="mb-2">Engineers Rate</h4>

              <v-text-field
                solo
                v-model="formFields.engTimerate"
                name="eng_rate"
                class="hide-arrows"
                prefix="£"
                type="number"
                @wheel="$event.target.blur()"
              ></v-text-field>

              <h4 class="mb-2">Engineers Time x 1</h4>

              <v-text-field
                solo
                v-model="formFields.engTime"
                name="eng_time"
                class="hide-arrows"
                suffix="hours"
                type="number"
                @wheel="$event.target.blur()"
              ></v-text-field>

              <h4 class="mb-2">Engineers Time x 1.5</h4>

              <v-text-field
                solo
                v-model="formFields.engTime_1_5"
                name="eng_time"
                class="hide-arrows"
                suffix="hours"
                type="number"
                @wheel="$event.target.blur()"
              ></v-text-field>

              <h4 class="mb-2">Engineers Time x 2</h4>

              <v-text-field
                solo
                v-model="formFields.engTime_2"
                name="eng_time"
                class="hide-arrows"
                suffix="hours"
                type="number"
                @wheel="$event.target.blur()"
              ></v-text-field>

              <h4 class="mb-2">Engineers Quote</h4>

              <v-text-field
                solo
                v-model="formFields.engTimeQuote"
                name="eng_quote"
                class="hide-arrows"
                prefix="£"
                type="number"
                @wheel="$event.target.blur()"
              ></v-text-field>

              <h4 class="mb-2">Engineer Notes</h4>

              <v-textarea
                solo
                v-model="formFields.engNotes"
                name="engNotes"
                label="Engineer Notes"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="3" class="mb-4 pa-6">
              <h4 class="mb-2">Parts Cost</h4>

              <v-text-field
                solo
                disabled
                v-model="formFields.engPartsQuote"
                name="eng_parts_cost"
                class="hide-arrows"
                prefix="£"
                type="number"
                @wheel="$event.target.blur()"
              ></v-text-field>

              <h4 class="mb-2">Parts Markup</h4>

              <v-text-field
                solo
                v-model="formFields.engPartsmarkup"
                name="eng_parts_markup"
                class="hide-arrows"
                suffix="%"
                type="number"
                @wheel="$event.target.blur()"
              ></v-text-field>

              <h4 class="mb-2">Parts Quote</h4>

              <v-text-field
                solo
                v-model="formFields.engParts"
                name="eng_parts_total"
                class="hide-arrows"
                prefix="£"
                type="number"
                @wheel="$event.target.blur()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item :value="'tab-3'">
          <v-row class="pa-4 ma-0">
            <v-col cols="12" sm="3" class="mb-4 pa-6">
              <SalJobBasicInfo />
            </v-col>

            <v-col cols="12" sm="6" class="mb-4 pa-6">
              <h4 class="mb-2">Sales Notes</h4>

              <v-textarea solo v-model="formFields.salNotes" name="sal_notes"></v-textarea>
            </v-col>

            <v-col cols="12" sm="3" class="mb-4 pa-6">
              <h4 class="mb-2">Carriage</h4>

              <v-text-field
                solo
                v-model="formFields.salCarriage"
                name="salCarriage"
                class="hide-arrows"
                prefix="£"
                type="number"
                @wheel="$event.target.blur()"
              ></v-text-field>

              <h4 class="mb-2">Packaging</h4>
              <v-text-field
                solo
                v-model="formFields.salPackaging"
                name="salPackaging"
                class="hide-arrows"
                prefix="£"
                type="number"
                @wheel="$event.target.blur()"
              ></v-text-field>

              <h4 class="mb-2">Sales Quote</h4>
              <v-text-field
                solo
                v-model="formFields.salQuote"
                name="salQuote"
                class="hide-arrows"
                prefix="£"
                type="number"
                @wheel="$event.target.blur()"
              ></v-text-field>

              <h4 class="mb-2">Emergency Service</h4>
              <v-select
                v-model="formFields.salIsEmergency"
                :items="warranty"
                label="Emergency Service?"
                item-value="value"
                item-text="name"
                solo
              ></v-select>

              <h4 class="mb-2">Emergency Charge £</h4>

              <v-text-field
                solo
                v-model="formFields.salEmergency"
                name="salEmergency"
                class="hide-arrows"
                prefix="£"
                type="number"
                @wheel="$event.target.blur()"
              ></v-text-field>

              <h4 class="mb-2">Delivery (Days)</h4>

              <v-text-field
                solo
                class="hide-arrows"
                suffix="days"
                v-model="formFields.salDeltime"
                type="number"
                @wheel="$event.target.blur()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item :value="'tab-4'">
          <v-row class="pa-4 ma-0">
            <v-col cols="12" sm="3" class="mb-4 pa-6">
              <v-textarea solo v-model="formFields.fault" name="fault" label="Faults"></v-textarea>

              <v-textarea
                solo
                v-model="formFields.faultdetails"
                name="faultdetails"
                label="Fault Details"
              ></v-textarea>

              <v-textarea
                solo
                v-model="formFields.repairs"
                name="repairs"
                label="Repairs"
              ></v-textarea>

              <v-textarea
                solo
                v-model="formFields.finaltest"
                name="finaltest"
                label="Final Test"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="9" class="mb-4 pa-6">
              <JobReportImages />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
      <v-row align="start" justify="end" class="mb-4 pa-6 pt-0">
        <v-btn
          :disabled="
            !valid ||
              isFetchingJobData ||
              isSubmitting ||
              !(
                ChangedFields &&
                Object.keys(ChangedFields).length > 0 &&
                ChangedFields.constructor === Object
              )
          "
          class="success ma-0 mr-6"
          type="submit"
          :loading="valid && isSubmitting"
          >Save Job</v-btn
        >
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import EngJobBasicInfo from '../singleJob/tab3/JobBasicInfo.vue';
import SalJobBasicInfo from '../singleJob/tab4/JobBasicInfo.vue';
import JobReportImages from '../singleJob/tab6/JobReportImages';
import axios from 'axios';
export default {
  name: 'EditJobForm',
  components: {
    EngJobBasicInfo,
    SalJobBasicInfo,
    JobReportImages,
  },
  data() {
    return {
      isUpdating: false,
      required_fields: [],
      dateClusterLoop: [
        { property_name: 'date_booked', label: 'Booked' },
        { property_name: 'engDate', label: 'Eng Quote Date' },
        { property_name: 'salDate', label: 'Sales Quote Date' },
        { property_name: 'appDate', label: 'Approved' },
        { property_name: 'staDate', label: 'Started' },
        { property_name: 'comDate', label: 'Completed' },
        { property_name: 'delDate', label: 'Delivered' },
        { property_name: 'invDate', label: 'Invoiced' },
      ],
      isLoadingContacts: false,
      isSubmitting: false,
      autoUpdate: true,
      datepicker_modal: false,
      automatic_redirect_after_edit: false,
      temp_date: new Date().toISOString().substr(0, 10),
      isFetchingJobData: false,
      datepicker_active_field: '',
      active_tab: 'tab-1',
      priorities: [
        { name: 'Normal', id: 2 },
        { name: 'High', id: 3 },
        { name: 'Emergency', id: 4 },
      ],
      warranty: [
        { name: 'No', value: false },
        { name: 'Yes', value: true },
      ],
      valid: false,
      search_key: '',
      formFields: {
        company_id: '',
        status_id: '',
        contact_id: '',
        customer_reference: '',
        order_number: '',
        description: '',
        manufacturer: '',
        machine_model: '',
        serial: '',
        tag_id: '',
        is_warranty: false,
        priority_id: '',
        internalnote: '',

        date_booked: '',
        engDate: '',
        salDate: '',
        appDate: '',
        staDate: '',
        comDate: '',
        delDate: '',
        invDate: '',

        bRnr: false,
        bBer: false,

        engTimerate: null,
        engTime: null,
        engTime_1_5: null,
        engTime_2: null,
        engTimeQuote: null,
        engNotes: '',

        engPartsQuote: null,
        engPartsmarkup: null,
        engParts: null,

        salNotes: '',
        salCarriage: null,
        salDeltime: null,
        salEmergency: null,
        salIsEmergency: null,
        salPackaging: null,
        salQuote: null,
        sal_user_id: null,

        user_id: null,

        fault: '',
        faultdetails: '',
        repairs: '',
        finaltest: '',
        subcontractor_id: '',
      },
      engineersList: [],
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        isNaN: (v) => this.isNumeric(v) == true || 'Wrong Value',
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  methods: {
    calculateEngTimeQuote() {
      const result =
        (this.formFields.engTime * this.formFields.engTimerate || 0) +
        (this.formFields.engTime_1_5 * this.formFields.engTimerate * 1.5 || 0) +
        (this.formFields.engTime_2 * this.formFields.engTimerate * 2 || 0);

      // Cap result to max 2 decimals
      this.formFields.engTimeQuote = parseFloat(result.toFixed(2));
    },
    filterManufacturers(item, queryText, itemText) {
      if (queryText < 3) return false;
      const hasValue = (val) => (val != null ? val : '');

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      /*console.log("query=" + query.toString().toLowerCase());
      console.log("text=" + text.toString().toLowerCase());
      console.log(this.binarySearch(query, this.ManufacturerList));*/
      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
      //return false;
    },
    show_datepicker(formfield) {
      this.datepicker_modal = true;
      this.datepicker_active_field = formfield;
      this.temp_date = '';
    },
    hide_datepicker() {
      this.datepicker_modal = false;
      this.datepicker_active_field = '';
      this.temp_date = '';
    },
    save_datepicker() {
      if (this.temp_date && this.datepicker_active_field) {
        this.formFields[this.datepicker_active_field] = this.temp_date;
      }
      this.hide_datepicker();
    },

    Submit() {
      console.log(this.formFields);
      console.log(this.validate());
      this.isSubmitting = true;
      /*var to_submit = {};
      var keys = Object.keys(this.formFields);
      for (var k = 0; k < keys.length; k++) {
        var name = keys[k];
        if (this.is_changed(name)) to_submit[name] = this.formFields[name];
      }
            console.log(to_submit);*/
      var payload = { ...this.ChangedFields };
      if (
        'is_warranty' in payload &&
        this.job.is_warranty != payload['is_warranty'] &&
        payload['is_warranty'] == true &&
        this.job.status_id < 5
      )
        payload['status_id'] = 5;
      //turned on but not yet go ahead
      else if (
        'is_warranty' in payload &&
        this.job.is_warranty != payload['is_warranty'] &&
        payload['is_warranty'] == false
      )
        payload['status_id'] = 2; //turned off

      return axios
        .post(
          process.env.VUE_APP_API_PROTOCOL +
            '://' +
            process.env.VUE_APP_API_HOST +
            ':' +
            process.env.VUE_APP_API_PORT +
            '/api/jobs/' +
            this.job.id +
            '/edit',
          payload
        )
        .then((response) => {
          console.log(response.data);

          var job = response.data;
          if (job && job.id) {
            console.log('Valid Job');
            this.isSubmitting = false;
            this.$store.commit('AddSnackbar', {
              message: 'Job updated successfully.',
            });
            this.$router.push('/job/' + this.job.id);
          } else console.log('Invalid Job');
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    is_changed(name) {
      if (
        this.formFields[name] == this.job[name] ||
        ((this.formFields[name] == null || this.formFields[name] == '') &&
          (this.job[name] == null || this.job[name] == ''))
      )
        return false;
      return true;
    },
    validate() {
      return this.$refs.EditJobForm.validate();
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },

  mounted() {
    this.isFetchingJobData = true;
    this.$store.dispatch('fetchDepartments');
    if (this.job.tag_id)
      this.$store
        .dispatch('fetchEngineersListStage6', { tag_id: this.job.tag_id })
        .then((engineers) => {
          this.engineersList = engineers;
        });

    this.$store.dispatch('fetchCustomersShortList').then(() => {
      this.formFields.priority_id = this.job.priority_id;
      this.formFields.order_number = this.job.order_number;
      this.formFields.customer_reference = this.job.customer_reference;
      this.formFields.company_id = this.job.company_id;
      this.formFields.contact_id = this.job.contact_id;

      this.formFields.description = this.job.description;

      this.formFields.manufacturer = this.job.manufacturer;
      this.formFields.machine_model = this.job.machine_model;
      this.formFields.serial = this.job.serial;
      this.formFields.tag_id = this.job.tag_id;

      this.formFields.internalnote = this.job.internalnote;
      this.formFields.fault = this.job.fault;

      this.formFields.date_booked = this.job.date_booked;
      this.formFields.engDate = this.job.engDate;
      this.formFields.comDate = this.job.comDate;
      this.formFields.salDate = this.job.salDate;
      this.formFields.appDate = this.job.appDate;
      this.formFields.staDate = this.job.staDate;
      this.formFields.delDate = this.job.delDate;
      this.formFields.invDate = this.job.invDate;

      this.formFields.status_id = this.job.status_id;

      this.formFields.is_warranty = Boolean(this.job.is_warranty);
      this.formFields.bBer = Boolean(this.job.bBer);
      this.formFields.bRnr = Boolean(this.job.bRnr);

      this.formFields.engTimerate = this.job.engTimerate;
      this.formFields.engTime = this.job.engTime;
      this.formFields.engTime_1_5 = this.job.engTime_1_5;
      this.formFields.engTime_2 = this.job.engTime_2;

      this.formFields.engTimeQuote = this.job.engTimeQuote;
      this.formFields.engNotes = this.job.engNotes;

      this.formFields.engPartsQuote = this.job.engPartsQuote;
      this.formFields.engPartsmarkup = this.job.engPartsmarkup;
      this.formFields.engParts = this.job.engParts;

      this.formFields.salNotes = this.job.salNotes;
      this.formFields.salCarriage = this.job.salCarriage;
      this.formFields.salDeltime = this.job.salDeltime;
      this.formFields.salEmergency = this.job.salEmergency;
      this.formFields.salIsEmergency = Boolean(this.job.salIsEmergency);
      this.formFields.salPackaging = this.job.salPackaging;
      this.formFields.salQuote = this.job.salQuote;
      this.formFields.sal_user_id = this.job.sal_user_id;

      this.formFields.fault = this.job.fault;
      this.formFields.faultdetails = this.job.faultdetails;
      this.formFields.repairs = this.job.repairs;
      this.formFields.finaltest = this.job.finaltest;
      this.formFields.user_id = this.job.user_id;
      this.formFields.subcontractor_id = this.job.subcontractor_id;

      this.isFetchingJobData = false;
    });

    this.$store.dispatch('fetchManufacturersList');
  },

  computed: {
    ChangedFields() {
      var to_submit = {};
      var keys = Object.keys(this.formFields);
      for (var k = 0; k < keys.length; k++) {
        var name = keys[k];
        if (this.is_changed(name)) to_submit[name] = this.formFields[name];
        else {
          //if not changed
          if (to_submit[name]) delete to_submit[name];
        }
      }
      return to_submit;
    },

    /*RequiredFieldsFilled() {
      let to_return = true;
      for (let i = 0; i < this.required_fields.length; i++) {
        if (!this.formFields[this.required_fields[i]]) {
          to_return =
            "Please make sure to fill the " +
            this.required_fields[i].toString() +
            " field";
        }
      }
      return to_return;
    },*/

    companyList() {
      return this.$store.getters.getCompanyList;
    },

    CompanyContactList() {
      return this.$store.getters.getCompanyContactList;
    },
    Departments() {
      return this.$store.getters.getDepartmentList;
    },
    job() {
      return this.$store.getters.getCurrentSingleJob;
    },
    get_all_status() {
      var statuses = this.$store.getters.get_all_status;

      var result = [];
      var temp;

      for (var i = 1; i <= 9; i++) {
        temp = {
          id: i,
          label: statuses[i.toString()],
        };

        result.push(temp);
        temp = {};
      }

      return result;
    },
    ManufacturerList() {
      let list = this.$store.getters.getManufacturersList;
      if (list.length == 0) return [];
      if (!this.search_key) return list['initial'];
      else
        return this.$store.getters.getManufacturersList[
          this.search_key
            .charAt(0)
            .toString()
            .toUpperCase()
        ];
    },
  },

  watch: {
    'formFields.engTimerate': 'calculateEngTimeQuote',
    'formFields.engTime': 'calculateEngTimeQuote',
    'formFields.engTime_1_5': 'calculateEngTimeQuote',
    'formFields.engTime_2': 'calculateEngTimeQuote',
    'formFields.company_id': function(new_selection, old_selection) {
      if (new_selection == null) {
        this.isLoadingContacts = true;
        this.$store.commit('setCompanyContactList', []);
        this.isLoadingContacts = false;
      } else if (
        new_selection != old_selection &&
        !isNaN(parseFloat(new_selection)) &&
        isFinite(new_selection)
      ) {
        this.isLoadingContacts = true;

        this.$store.dispatch('fetchCompanyContactList', new_selection).finally(() => {
          this.isLoadingContacts = false;
        });
      }
    },
    /* "formFields.status_id": function (new_selection, old_selection) {
      /*if (new_selection != old_selection && new_selection == 9)
        this.required_fields.push("invDate");
      else {
        var index = this.required_fields.indexOf("invDate");
        if (index !== -1) {
          this.required_fields.splice(index, 1);
        }
      }
    },*/
  },
};
</script>

<style></style>
