<template>
  <v-tab-item class="pa-3">
    <v-row>
      <v-col class="px-5" md="3" sm="6" cols="12">
        <JobBasicInfo />
      </v-col>
      <SalQuotationTotal />
      <SalQuotationOptions />
      <SalNotes />
    </v-row>
  </v-tab-item>
</template>

<script>
import JobBasicInfo from './JobBasicInfo';
import SalNotes from './SalNotes.vue';
import SalQuotationTotal from './SalQuotationTotal';
import SalQuotationOptions from './SalQuotationOptions';

export default {
  name: 'SalQuotationTab',
  components: {
    JobBasicInfo,
    SalNotes,
    SalQuotationTotal,
    SalQuotationOptions,
  },
};
</script>

<style></style>
